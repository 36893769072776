body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0.6;
    scale: 0.7;
  }

  70%{
    opacity: 0.6;
    scale: 0.7;
  
  }

  100% {
    opacity: 0;
    transform: translateX(-120%);
  }
}

.outAnimation {
  animation: outAnimation 800ms ease-in-out forwards;
}
.source-sans{
  font-family: 'Source Sans 3';
}